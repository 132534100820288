import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${({ active }) => (active ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  z-index: 1;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  background-color: white;
  width: 340px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 15px;
  gap: 15px;

  .label {
    font-size: 16px;
    margin-top: 30px;
  }

  .back-button {
    margin-bottom: 30px;
  }

  button {
    width: 80%;
  }
`;

export const AddCreditCard = styled.button`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border: 1.5px dashed #4d4d4d;
  border-radius: 16px;
  padding: 12px 0;

  &:hover {
    cursor: pointer;
    scale: 1.01;
    transition: all 0.2s ease-in-out;
  }

  span {
    font-size: 12px;
  }
`;

export const CreditCardsList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

export const Loader = styled.div`
  animation: spin 1s linear infinite;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #4d4d4d;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-top: 10px;
`;

export const CreditCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 80%;
  margin-bottom: 12px;
`;

export const CreditCardInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  width: 100%;
`;

export const CreditCardSelector = styled.input.attrs({
  type: 'radio',
  name: 'credit-card',
})`
  height: 16px;
  width: 16px;
  &:checked + div {
    border-color: red;
    color: red;
  }
`;

export const CreditCardFormWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 80%;

  .address-label {
    font-size: 12px;
    margin-bottom: 5px;
    margin-top: 12px;
  }

  > div {
    width: 100%;
    height: 40px;
    border-radius: 10px;

    * {
      font-size: 12px;
      border-radius: 8px !important;
    }
  }

  > span {
    width: 100%;
    display: flex;
    justify-content: space-between;

    > div {
      width: 49%;
      height: 40px;
      border-radius: 10px;

      * {
        font-size: 12px;
        border-radius: 8px !important;
      }
    }
  }
`;

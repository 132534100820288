/* eslint-disable react/react-in-jsx-scope */
// import { NButton } from '~/components';

import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useApi } from '~/hooks';
import { NButton, NInput } from '~/components';
import { addCard, getCards } from '~/services/users';
import {
  AddCreditCard,
  Container,
  CreditCard,
  CreditCardFormWrapper,
  CreditCardInfo,
  CreditCardSelector,
  CreditCardsList,
  Loader,
  Wrapper,
} from './styles';

const CreditCardModal = ({
  active,
  onChange = () => null,
  onClose = () => null,
  onConfirm = () => null,
}) => {
  const [ip, setIp] = useState('');
  const [mode, setMode] = useState('list');
  const [cards, setCards] = useState([]);
  const [isCardsLoading, setIsCardsLoading] = useState(false);
  const { register, handleSubmit } = useForm();
  const { request } = useApi();
  const [someCreditCardWasSelected, setSomeCreditCardWasSelected] =
    useState(false);

  const labels = {
    add: 'Adicionar novo cartão',
    list: 'Selecione o cartão de crédito',
  };

  const getButtonLabel = () => {
    if (mode === 'add') return 'Adicionar';

    if (someCreditCardWasSelected) return 'Selecionar';

    return 'Voltar';
  };

  const fetchCards = async () => {
    setIsCardsLoading(true);
    const response = await getCards();
    setCards(response.data);
    setIsCardsLoading(false);
  };

  const getIp = async () => {
    const response = await fetch('https://api64.ipify.org?format=json');
    const data = await response.json();
    setIp(data.ip);
  };

  useEffect(() => {
    getIp();
    fetchCards();
  }, []);

  const onSubmit = data => {
    const expiryMonth = data.expirationDate.split('/')[0];
    const expiryYear = data.expirationDate.split('/')[1];

    request(
      () =>
        addCard({
          holderName: data.holderName,
          number: data.number,
          ccv: data.ccv,
          expiryMonth,
          expiryYear,
          holder: {
            email: data.holder.email,
            document: data.holder.document,
            postalCode: data.holder.postalCode,
            addressNumber: data.holder.addressNumber,
            addressComplement: data.holder.addressComplement,
          },
          ip,
        }),
      () => {
        setMode('list');
        fetchCards();
        return 'Cartão adicionado com sucesso!';
      }
    );
  };

  return (
    <Container active={active}>
      <Wrapper>
        <p className="label">{labels[mode]}</p>
        {mode === 'add' && (
          <CreditCardFormWrapper>
            <NInput
              {...register('holderName')}
              required
              placeholder="Nome do titular"
            />
            <NInput
              {...register('number')}
              required
              placeholder="Número do cartão"
              mask="9999 9999 9999 9999"
            />
            <span>
              <NInput
                {...register('expirationDate')}
                required
                placeholder="Validade"
                mask="99/99"
              />
              <NInput
                {...register('ccv')}
                required
                placeholder="CVV"
                mask="999"
              />
            </span>

            <NInput
              {...register('holder.email')}
              required
              placeholder="E-mail do titular"
            />

            <NInput
              {...register('holder.document')}
              required
              placeholder="CPF do titular"
              mask="999.999.999-99"
            />

            <span className="address-label">Endereço da fatura</span>

            <NInput
              {...register('holder.postalCode')}
              required
              placeholder="CEP"
              mask="99999-999"
            />

            <NInput
              {...register('holder.addressNumber')}
              required
              placeholder="Número"
            />

            <NInput
              {...register('holder.addressComplement')}
              required
              placeholder="Complemento"
            />
          </CreditCardFormWrapper>
        )}

        {mode === 'list' && (
          <CreditCardsList>
            {isCardsLoading ? (
              <Loader />
            ) : (
              cards.map(card => (
                <CreditCard key={card.id}>
                  <CreditCardInfo>
                    <span>
                      {card.brand[0] + card.brand.slice(1).toLowerCase()}{' '}
                    </span>
                    <span>{card.finalNumber}</span>
                  </CreditCardInfo>

                  <CreditCardSelector
                    id={card.id}
                    onChange={e => {
                      onChange(e.target.checked, card.id);
                      setSomeCreditCardWasSelected(true);
                    }}
                  />
                </CreditCard>
              ))
            )}
            <AddCreditCard onClick={() => setMode('add')}>
              <span>Adicionar novo cartão</span>
            </AddCreditCard>
          </CreditCardsList>
        )}

        <NButton
          className="back-button"
          color="primary"
          onClick={() => {
            if (mode === 'add') {
              handleSubmit(onSubmit)();
            } else if (someCreditCardWasSelected) {
              onClose();
              onConfirm();
            } else {
              onClose();
            }
          }}
          label={getButtonLabel()}
        />
      </Wrapper>
    </Container>
  );
};

export default CreditCardModal;

/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable consistent-return */
/* eslint-disable arrow-body-style */

import { useEffect, useState } from 'react';
import { IoCashOutline } from 'react-icons/io5';
import { TiArrowBackOutline } from 'react-icons/ti';
import { MdOutlinePix } from 'react-icons/md';
import { BsCreditCardFill } from 'react-icons/bs';
import Sound from '../../assets/audios/popup.mp3';
import Alert from '../../assets/icons/alert.svg';
import Divisor from '../../assets/images/divisor.svg';
import PassengerImage from '../../assets/images/PassengerProfileImage2.jpg';
import PointedDivisorImg from '../../assets/images/pointedDivisor.svg';
import {
  Accept,
  Actions,
  ActionsRow,
  Container,
  Details,
  HeaderItemText,
  HeaderRight,
  HeaderRightItem,
  Left,
  Notification,
  NotifyIcon,
  Others,
  PointedDivisor,
  Price,
  Profile,
  ProfileImage,
  ProfileInfo,
  ProfileName,
  ProfileSince,
  Reject,
  Route,
  RouteAddress,
  RouteHeader,
  RouteInfoValues,
  RouteItem,
  RouteItem2,
  RouteList,
  Seats,
  Wrapper,
} from './styles';

function TripPopup({ trip, onAccept, onRefuse, driverLat, driverLng }) {
  const [timeTo, setTime] = useState('');
  const [distanceTo, setDistance] = useState('');
  useEffect(() => {
    const audio = new Audio(Sound);

    audio.play();

    return () => audio.pause();
  }, []);

  function getCreatedDate(date) {
    const createdAt = new Date(date);
    return createdAt.toLocaleDateString('pt-BR');
  }

  console.log(trip);

  const fetchTimePassenger = (to, from) => {
    const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${to.lng},${to.lat};${from.lng},${from.lat}?access_token=${process.env.REACT_APP_MAP_BOX_API_KEY}`;
    const [passengerTime, setPassengerTime] = useState('');
    const [passengerDistance, setPassengerDistance] = useState('');
    try {
      fetch(url)
        .then(response => response.json())
        .then(data => {
          const durationInSeconds = data.routes[0].duration;
          const durationInMinutes = (durationInSeconds / 60).toFixed(0);
          const durationInHours = (durationInMinutes / 60)
            .toFixed(2)
            .toString()
            .split('.');
          const parsedDurationInHours = 60 / (100 / Number(durationInHours[1]));
          setPassengerTime(
            durationInMinutes <= 59
              ? `${durationInMinutes}min`
              : `${durationInHours[0]}:${parsedDurationInHours.toFixed(0).length === 1
                ? `0${parsedDurationInHours.toFixed(0)}`
                : parsedDurationInHours.toFixed(0)
              }min`
          );

          const distanceInMeters = data.routes[0].distance;
          const distanceInKilometers = (distanceInMeters / 1000).toFixed(2);
          setPassengerDistance(
            distanceInMeters <= 999
              ? `${distanceInMeters}metros`
              : `${distanceInKilometers}kms`
          )
        })
        .catch(err => {
          console.log(err);
        });
    } catch {
      setTime('Aconteceu um erro');
    }
    return `${passengerTime} (${passengerDistance})`;
  };

  const getPassengerCost = passengerId => {
    const data = trip.cost.costPerPassenger.find(
      passenger => passenger.passengerId === passengerId
    );

    return data.cost;
  };

  useEffect(() => {
    const waypoints = [];
    trip.passengers.map((passenger, index) => (
      index === 0 ? waypoints.push({ from: { lng: passenger.boardingAddress.longitude, lat: passenger.boardingAddress.latitude }, to: { lng: passenger.landingAddress.longitude, lat: passenger.landingAddress.latitude } }) : waypoints.push({ from: {}, to: { lng: passenger.landingAddress.longitude, lat: passenger.landingAddress.latitude } })
    ))

    const from = waypoints.map((waypoint, index) => (
      index === 0 ? { lng: waypoint.from.lng, lat: waypoint.from.lat } : ''
    ))
    const fromLocation = `${from[0].lng},${from[0].lat};`

    const to = waypoints.map((waypoint) => (
      waypoint.to
    ))
    const toPoints = to.map((point) => (
      `${point.lng}, ${point.lat};`
    ))
    const toLocation = toPoints.join(" ");

    const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${fromLocation}${(toLocation.slice(0, toLocation.length - 1))}?access_token=${process.env.REACT_APP_MAP_BOX_API_KEY}`;

    try {
      fetch(url)
        .then(response => response.json())
        .then(data => {
          const durationInSeconds = data.routes[0].duration;
          const durationInMinutes = (durationInSeconds / 60).toFixed(0);
          const durationInHours = (durationInMinutes / 60)
            .toFixed(2)
            .toString()
            .split('.');
          const parsedDurationInHours = 60 / (100 / Number(durationInHours[1]));
          setTime(
            durationInMinutes <= 59
              ? `${durationInMinutes}min`
              : `${durationInHours[0]}:${parsedDurationInHours.toFixed(0).length === 1
                ? `0${parsedDurationInHours.toFixed(0)}`
                : parsedDurationInHours.toFixed(0)
              }min`
          );

          const distanceInMeters = data.routes[0].distance;
          const distanceInKilometers = (distanceInMeters / 1000).toFixed(2);
          setDistance(
            distanceInMeters <= 999
              ? `${distanceInMeters}metros`
              : `${distanceInKilometers}kms`
          )
        })
        .catch(err => {
          console.log(err);
        });
    } catch {
      setTime('Aconteceu um erro');
    }
  }, [])

  return (
    <Container>
      <Wrapper>
        <div>
          <Notification>
            <NotifyIcon>
              <img src={Alert} alt="Alert icon" />
            </NotifyIcon>
            <span>Nova chamada</span>
            <Seats>{trip.seats}</Seats>
          </Notification>
          <Details>
            <Price>{trip.cost.total}</Price>
            <Others>R${(((parseFloat(((parseFloat(((trip.cost.total).slice(3)).replace(/,/gi, ".")))))) / parseFloat(distanceTo.slice(0, distanceTo.length - 3))).toFixed(2))} Por km rodado</Others>
            <Others>R${((parseFloat(((parseFloat(((trip.cost.total).slice(3)).replace(/,/gi, ".")))))) / parseFloat(timeTo.slice(0, timeTo.length - 3))).toFixed(2)} Por min rodado</Others>
            <img src={Divisor} alt="Divisor" className="divisor" />
            {trip.passengers.map((passenger) => (
              <Route key={trip.id}>
                <RouteHeader>
                  <Profile>
                    <ProfileImage src={PassengerImage} />
                    <ProfileInfo>
                      <ProfileName>{passenger.passengerName}</ProfileName>
                      <ProfileSince>Desde: {getCreatedDate(passenger.createdAt)}</ProfileSince>
                    </ProfileInfo>
                  </Profile>
                  <HeaderRight>
                    <HeaderRightItem>
                      {trip.paymentMethod === 1 && (
                        <>
                          <IoCashOutline size={25} color="#22f100" />
                          <HeaderItemText>
                            Dinheiro
                          </HeaderItemText>
                        </>
                      )}
                      {trip.paymentMethod === 2 && (
                        <>
                          <MdOutlinePix size={25} color="#00BDAE" />
                          <HeaderItemText>
                            Pix
                          </HeaderItemText>
                        </>
                      )}
                      {trip.paymentMethod === 3 && (
                        <>
                          <BsCreditCardFill size={25} color="#000" />
                          <HeaderItemText>
                            Cartão de crédito
                          </HeaderItemText>
                        </>
                      )}
                    </HeaderRightItem>
                    {trip.hasReturn ? (<HeaderRightItem>
                      <TiArrowBackOutline size={25} color="#ffffff" />
                      <HeaderItemText>
                        Retorno
                      </HeaderItemText>
                    </HeaderRightItem>) : ('')}
                  </HeaderRight>
                </RouteHeader>
                <RouteInfoValues>{getPassengerCost(passenger.passengerId)} - {fetchTimePassenger({ lng: passenger.boardingAddress.longitude, lat: passenger.boardingAddress.latitude }, { lng: driverLng, lat: driverLat })} - {fetchTimePassenger({ lng: passenger.boardingAddress.longitude, lat: passenger.boardingAddress.latitude }, { lng: passenger.landingAddress.longitude, lat: passenger.landingAddress.latitude })} </RouteInfoValues>
                <RouteAddress>
                  <Left>
                    <PointedDivisor
                      src={PointedDivisorImg}
                      alt="Pointed divisor"
                    />
                    <RouteList>
                      <RouteItem>{passenger.boardingAddress.name}</RouteItem>
                      <RouteItem2>{passenger.landingAddress.name}</RouteItem2>
                    </RouteList>
                  </Left>
                </RouteAddress>
              </Route>
            ))}
          </Details>
        </div>
        <Actions>
          <ActionsRow>
            <Accept onClick={onAccept}>Aceitar</Accept>
            <Reject onClick={onRefuse}>Recusar</Reject>
          </ActionsRow>
        </Actions>
      </Wrapper>
    </Container>
  );
}

export default TripPopup;

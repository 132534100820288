/* eslint-disable prettier/prettier  */
/* eslint-disable no-unused-vars  */
import React, { useEffect } from 'react';

import {
  useAlerts,
  useApi,
  useApiEffect,
  useApp,
  useAuth,
  useSocketContext,
} from '~/hooks';
import { getFirst, setApp, setFirst } from '~/repositories/app';
import { getAuth, removeAuth } from '~/repositories/auth';
import { getAppByDomain } from '~/services/apps';
import { updatePassenger, getPassenger } from '~/services/passengers';
import { updateDriverLogin } from '~/services/drivers';
import { getDomain, manifest, updateManifest } from '~/utils';
import { Loading } from '~/components';
import useSocket from '~/socket';

const LoadingPage = ({ hideLoading = () => null }) => {
  const { setUser } = useAuth();
  const { setSocket } = useSocketContext();
  const { updateApp } = useApp();

  const { newErrorAlert } = useAlerts();

  const { request } = useApi();

  const { createWebsocketClient } = useSocket();
  let newSocket;

  useEffect(() => {
    if (!getFirst()) {
      window.location.reload(true);
      setFirst(true);
    }
  }, []);

  useApiEffect(
    () => {
      const domain = getDomain();

      return getAppByDomain(domain);
    },
    response => {
      const data = response.data.content[0];
      if (!data) {
        throw new Error();
      }

      manifest.name = `${data.name} Travel App`;
      manifest.short_name = data.name;
      document.title = data.name;

      if (data.colors.primary !== null) {
        manifest.theme_color = data.colors.primary;
        manifest.background_color = data.colors.primary;
      } else {
        data.colors.primary = undefined;
      }

      if (data.logo !== null) {
        manifest.icons = [
          {
            src: data.logo,
            sizes: '192x192',
            type: 'image/png',
          },
        ];
      }

      updateManifest();

      setApp({ ...data, domain: getDomain() });

      updateApp(data);

      const { isPassenger } = getAuth();

      const getUser = isPassenger ? getPassenger : updateDriverLogin;

      request(
        () => getUser({}),
        user => {
          newSocket = createWebsocketClient(
            () => null,
            () => null,
            (err) => 
              newErrorAlert(
                err.message || 'Ocorreu algum erro, tente recarregar a página',
                'socket'
              )
          );
          setSocket(newSocket);
          setUser(user.data);
        },
        () => {
          removeAuth();

          hideLoading();
        }
      );
    },
    () => {
      hideLoading();

      return 'Domínio não encontrado!';
    }
  );

  return <Loading />;
};

export default LoadingPage;

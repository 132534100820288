import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  padding: 4rem 0;
  background: linear-gradient(
    to top,
    white 0%,
    white 78%,
    ${({ theme }) => theme.colors.primary} 60%,
    ${({ theme }) => theme.colors.primary} 100%
  );

  display: flex;
  flex-direction: column;
  align-items: center;

  > .div_timers {
    display: flex;
    flex-direction: column;

    max-height: 55vh;
    overflow: auto;

    > .timer {
      margin-bottom: 25px;
    }

    > .text_warning {
      width: 100%;
      padding: 0 20px 0 20px;
      text-align: center;
    }
  }

  > .title,
  > .button,
  > .counter,
  > .button_small {
    // position: absolute;
  }

  > .title {
    top: 45px;
  }

  > .counter {
    top: 95px;
  }

  > .button {
    bottom: 55px;
  }

  > .button_small {
    bottom: 125px;
  }

  .label-payments {
    margin-top: 24px;
  }
`;

export const Header = styled.header`
  width: 100%;
  padding-left: 30px;
  position: absolute;
  top: 2rem;
`;

export const BackButton = styled.button`
  margin-top: -0.5rem;
  margin-left: 0.5rem;

  svg {
    opacity: 0.8;
  }
`;

export const Categories = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-top: 48px;
`;

export const Category = styled.div`
  width: 150px;
  height: 150px;
  background-color: white;
  border-radius: 16px;
  border: ${({ active }) => (active ? `2px solid #000` : '1px solid #4d4d4d')};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
`;

export const Top = styled.div`
  display: flex;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;

export const Title = styled.div`
  color: black;
  font-size: 16px;
  width: 100%;
  padding-left: 9px;
`;

export const Description = styled.div`
  width: 100%;
  color: gray;
  font-size: 12px;
  padding-left: 9px;
  margin-top: 5px;
`;

export const Price = styled.div`
  width: 100%;
  color: black;
  font-size: 18px;
  padding-left: 9px;
  margin-top: 10px;
`;

export const Payments = styled.div`
  margin-top: 10px;
  display: flex;
  max-width: 320px;
  gap: 20px;
`;

export const PaymentOptionSelected = styled.div`
  background-color: #d9d9d9cc !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 110px;
  height: 65px;
  background-color: white;
  border-radius: 10px;

  border: 1px solid black;

  img {
    max-width: 40px;
  }

  &:hover {
    cursor: pointer;
  }

  span {
    margin-top: 5px;
    font-size: 12px;
    text-align: center;
  }
`;

export const PaymentOption = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 110px;
  height: 65px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid black;

  img {
    max-width: 40px;
  }

  &:hover {
    cursor: pointer;
  }

  span {
    margin-top: 5px;
    font-size: 12px;
    text-align: center;
  }

  .selected {
    border-color: red;
    color: red;
  }
`;
